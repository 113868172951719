const constantsFactory = () => {
  const KEYS = {
    DOWN: 40,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    UP: 38,
    LEFT: 37,
    RIGHT: 39,
    TOP_0: 48,
    TOP_1: 49,
    TOP_2: 50,
    TOP_3: 51,
    TOP_4: 52,
    NUM_0: 96,
    NUM_1: 97,
    NUM_2: 98,
    NUM_3: 99,
    NUM_4: 100,
  };

  const EVENTS = {
    CLICK_ON_LOGIN: 'CLICK_ON_LOGIN',
    CLICK_ON_JOIN: 'CLICK_ON_JOIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    REGISTRATION_SUCCESSFUL: 'REGISTRATION_SUCCESSFUL',
    NEWSLETTER_DISMISS: 'NEWSLETTER_DISMISS',
    CLICK_TELL_ABOUT_YOURSELF: 'CLICK_TELL_ABOUT_YOURSELF',
    USER_UPDATED: 'USER_UPDATED',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    NOT_AUTHORISED: 'NOT_AUTHORISED',
    GUEST_SESSION_CREATED: 'GUEST_SESSION_CREATED',

    CLICK_PRODUCT: 'CLICK_PRODUCT',
    SETTINGS_CHANGED: 'SETTINGS_CHANGED',
    BASKET_UPDATED: 'BASKET_UPDATED',
    CLICK_COMMISSION_ARTIST: 'CLICK_COMMISSION_ARTIST',
    CLICK_CONTACT_ARTIST: 'CLICK_CONTACT_ARTIST',
    CLICK_CHANGE_SETTINGS: 'CLICK_CHANGE_SETTINGS',
    CLICK_ON_MAKE_OFFER: 'CLICK_ON_MAKE_OFFER',
    MAKE_OFFER_SUCCESS: 'MAKE_OFFER_SUCCESS',
    CLICK_PAY_IN_INSTALMENTS: 'CLICK_PAY_IN_INSTALMENTS',
    CLICK_ADD_TO_COLLECTION: 'CLICK_ADD_TO_COLLECTION',
    PRODUCT_ADDED_TO_COLLECTION: 'PRODUCT_ADDED_TO_COLLECTION',
    CLICK_CREATE_NEW_COLLECTION: 'CLICK_CREATE_NEW_COLLECTION',
    OPEN_NOTIFICATION_SLIDER: 'OPEN_NOTIFICATION_SLIDER',
    CLOSE_NOTIFICATION_SLIDER: 'CLOSE_NOTIFICATION_SLIDER',
    COLLECTION_FETCHED: 'COLLECTION_FETCHED',
    SETTINGS_FETCHED: 'SETTINGS_FETCHED',
    OPEN_SIDE_MENU: 'OPEN_SIDE_MENU',
    OPEN_SEARCH: 'OPEN_SEARCH',
    OPEN_BASKET: 'OPEN_BASKET',
    OPEN_CONFIRMATION_MSG: 'OPEN_CONFIRMATION_MSG',
    INCREASE_BASKET_COUNT: 'INCREASE_BASKET_COUNT',
  };

  const ROLES = {
    USER: 'user',
    PROVIDER: 'provider',
    STAFF: 'staff',
  } as const;

  const DATA_TEST_ID = {
    COUNT_NOTIFICATION: 'countNotification',
    DROPDOWN: 'dropdown',
    DESKTOP_NAVIGATION: 'desktopNavigation',
    MOBILE_NAVIGATION: 'mobileNavigation',
    SIDE_NAVIGATION: 'sideNavigation',
    SIDE_NAVIGATION_SECOND_LEVEL: 'sideNavigationSecondLevel',
    RATING: 'rating',
    HOMEPAGE_STYLED: 'StyledHomepage',
    HOMEPAGE_STYLED_LAYOUT_ARTISTS_SECTIONS: 'StyledLayoutArtistsSections',
  } as const;

  const BREAKPOINTS = {
    MEDIUM_AND_DOWN: '(max-width: 991px)',
    MEDIUM_AND_UP: '(min-width: 768px)',
    SMALL_AND_UP: '(min-width: 576px)',
  };

  const TRACKING = {
    IMPRESSIONS: '/api/tracking/impressions/',
    VISITS: '/t/visit/',
  };

  const TEXT = {
    RECOMMENDED_HEADLINE: "Art we think you'll love",
  };

  const API = {
    FAVOURITES: '/collections/api/favourites/',
    COLLECTIONS_API: '/collections/api/',
    COLLECTION_API: '/api/v2/collection/',
    SETTINGS_API: '/api/u/',
    EDITORS_PICK_API: '/api/content/featured-collections/',
    CURRENCY_RATES_API: '/api/product/currency-rates/',
    PRODUCT_SEARCH_API: '/api/v2/product/s/',
    PRODUCT_COUNT_API: '/api/v2/product/s/_count/',
    PRICE_DISTRIBUTION: '/api/v2/product/s/_prices/',
    SEO_TEXT_API: '/shop/page-data/',
    PRODUCT: '/api/product/',
    PROMOTE_PRODUCT: '/api/marketing/promote/product/',
    PRODUCT_THEMES: '/editors-picks/themes/',
    PRODUCT_CAMPAIGNS: '/dashboard/marketing/api/campaign/',
    RECENTLY_VIEWED: '/api/recently-viewed/',
    IMPRESSIONS: '/api/tracking/impressions/',
    PAGE_VIEW: '/t/event/',
    VISIT: '/t/visit/',
    PRODUCT_CLICK: '/api/tracking/clicks/',
    SEARCH_AUTOCOMPLETE: '/api/search/autocomplete/',
    JOIN_MAILING_LIST: '/api/marketing/subscribe/newsletter/',
    ARTISTS: '/api/v2/artists/',
  };

  const TIMEOUT = {
    PRODUCT_SEARCH_API_TIMEOUT: 1000, // 1 second
  };

  const CACHE = {
    EDITORS_PICK_CACHE_TTL_MILLISECONDS: 2 * 60 * 60 * 1000, // for redis 2 hours in milliseconds
    PRODUCT_CACHE_TTL_SECONDS: 30 * 60, // 30 minutes
    PRODUCT_CACHE_STALE_WHILE_REVALIDATE_TTL_SECONDS: 2 * 60 * 60, // 2 hours
    ARTIST_CACHE_TTL_SECONDS: 30 * 60, // 30 minutes
    ARTIST_CACHE_STALE_WHILE_REVALIDATE_TTL_SECONDS: 2 * 60 * 60, // 2 hours
    PLP_CACHE_TTL_SECONDS: 30 * 60, // 30 minutes
    PLP_CACHE_STALE_WHILE_REVALIDATE_TTL_SECONDS: 6 * 60 * 60, // 6 hours
  };

  return {
    KEYS,
    EVENTS,
    ROLES,
    BREAKPOINTS,
    TRACKING,
    TEXT,
    API,
    TIMEOUT,
    CACHE,
    DATA_TEST_ID,
  };
};

export default constantsFactory;
