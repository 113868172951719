import styled, {createGlobalStyle} from 'styled-components';
import colors from '../src/atomic-design/theme/constants/colors';
import {StyledSectionProps} from './typings';
import container from '@/theme/constants/container';
import {sizePx} from '@/theme/helpers/size';

/**
 * @description Global styles:
 * - classNames for assistive technologies, see example link below
 * @link https://tailwindcss.com/docs/screen-readers
 */
export const GlobalStyle = createGlobalStyle`
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
`;

const sectionVerticalPadding = {
  l: 32,
  m: 20,
  s: 12,
};

export const StyledPage = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledSection = styled.section<StyledSectionProps>`
  background: ${(props) => props.$background ?? colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
      props.$verticalPadding
        ? sizePx(sectionVerticalPadding[props.$verticalPadding])
        : sizePx(sectionVerticalPadding.l)}
    16px;
  overflow: ${(props) => (props.$overflow ? 'visible' : 'hidden')};

  > * {
    max-width: ${container.maxWidth};
    width: 100%;
  }
`;

export default StyledPage;
