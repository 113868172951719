import '../vendors/bootstrap/dist/css/bootstrap.css';
import '../src/app.css';

import '../src/components/tab/tab.css';
import '../src/components/carousel/carousel.css';
import '../src/components/dropdown/dropdown.css';
import '../src/components/artist-avatar/artist-avatar.css';

import '../src/components/navbar/navbar.css';
import '../src/components/navbar-search/navbar-search.css';
import '../src/components/basket-modal/basket-modal.css';
import '../src/components/artist/artist.css';
import '../src/components/rating/rating.css';
import '../src/components/subnav/subnav.css';
import '../src/components/cards/product-card/product-card.css';
import '../src/components/lazy-image/lazy-image.css';
import '../src/components/footer/footer.css';
import '../src/components/social-buttons/social-buttons.css';
import '../src/components/dropdown-select/dropdown-select.css';
import '../src/components/filter-section/filter-section.css';
import '../src/components/modal-login/modal-login.css';
import '../src/components/modal-join/modal-join.css';
import '../src/components/modal-newsletter/modal-newsletter.css';
import '../src/components/alert-welcome/alert-welcome.css';
import '../src/components/modal-welcome/modal-welcome.css';
import '../src/components/modal-yourself/modal-yourself.css';
import '../src/components/breadcrumb/breadcrumb.css';
import '../src/components/product-carousel/product-carousel.css';
import '../src/components/recommended-widget/recommended-widget.css';
import '../src/components/product-summary/product-summary.css';
import '../src/components/product-attributes/product-attributes.css';
import '../src/components/collapsible/collapsible.css';
import '../src/components/product-carousel-full-screen/product-carousel-full-screen.css';
import '../src/components/carousel-scroll/carousel-scroll.css';
import '../src/components/product-carousel-vertical/product-carousel-vertical.css';
import '../src/components/review-ratings/review-ratings.css';
import '../src/components/product-buy-two-artworks/product-buy-two-artworks.css';
import '../src/components/modal-instalments-deko/modal-instalments-deko.css';
import '../src/components/product-artwork-description/product-artwork-description.css';
import '../src/components/artist-menu/artist-menu.css';
import '../src/components/product-artist/product-artist.css';
import '../src/components/modal-add-to-collection/modal-add-to-collection.css';
import '../src/components/modal-make-offer/modal-make-offer.css';
import '../src/components/social-share/social-share.css';
import '../src/components/product-btn-set/product-btn-set.css';
import '../src/components/product-additional-info/product-additional-info.css';
import '../src/components/product-btn-dislike/product-btn-dislike.css';
import '../src/components/notification-slider/notification-slider.css';
import '../src/components/product-carousel-related/product-carousel-related.css';
import '../src/components/banner/banner.css';
import '../src/components/banner-carousel/banner-carousel.css';
import '../src/components/trust-elements/trust-elements.css';
import '../src/components/cards/artist-card/artist-card.css';
import '../src/components/card-ribbon/card-ribbon.css';
import '../src/components/artist-commission/artist-commission.css';
import '../src/components/side-menu/side-menu.css';
import '../src/components/modal-confirmation/modal-confirmation.css';
import '../src/components/showcase-section/showcase-section.css';
import '../src/components/search-bar/search-bar.css';
import '../src/components/info-icon/info-icon.css';
import '../src/components/masonry-gallery/masonry-gallery.css';

import '../src/components/tactical/saleCountdown/saleCountdown.css';
import '../src/components/tactical/scarcity/scarcity.css';
import '../src/components/tactical/customerCues/addToBasket/addToBasket.css';
import '../src/components/tactical/customerCues/freeShipping/freeShipping.css';

import './artist/artist.css';
import './product/product.css';
import './art/art.css';

import {Provider} from 'react-redux';
import {useStore} from '../redux/store';
import elementFactory from '../src/utils/element';
import toastFactory from '../vendors/bootstrap/components/toast';

import React, {useEffect} from 'react';
import AlertWelcome from '../src/components/alert-welcome/alert-welcome';
import ModalLogin from '../src/components/modal-login/modal-login';
import ModalJoin from '../src/components/modal-join/modal-join';
import ModalNewsletter from '../src/components/modal-newsletter/modal-newsletter';
import ModalWelcome from '../src/components/modal-welcome/modal-welcome';
import ModalYourself from '../src/components/modal-yourself/modal-yourself';
import ModalJoinThanks from '../src/components/modal-join-thanks/modal-join-thanks';
import ModalRegionalSettings from '../src/components/modal-regional-settings/modal-regional-settings';
import NotificationSlider from '../src/components/notification-slider/notification-slider';
import ModalConfirmation from '../src/components/modal-confirmation/modal-confirmation';
import {loadSettingsOptions, loadUserSettings} from '../redux/actions/settingsActions';
import {useDispatch} from 'react-redux';
import {customEvent as gtmEvent, data as gtmData, getInitialDataLayer} from '../src/utils/gtm';
import 'intersection-observer';
import useLayoutEffect from './../src/utils/use-isomorphic-layout-effect';
import {bugsnagStart} from '../src/services/bugsnag';
import {GlobalStyle} from './styles';
import '../src/i18n';

bugsnagStart();

const AppComponents = ({children}) => {
  // This components wrapper allows the use of hooks within the core app system
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSettingsOptions());
    dispatch(loadUserSettings());
  }, []);
  return children;
};

/**
 * Main Entry for React.
 * @param {any} Component TThe component to render.
 * @param {any} pageProps The props for the app components.
 * @param {any} err Any errors from or to the app.
 * @return {object} The app for initialisation.
 */
const MyApp = ({Component, pageProps, err}) => {
  const store = useStore(pageProps.initialReduxState);

  useLayoutEffect(() => {
    try {
      const initData = getInitialDataLayer();
      gtmData(initData);
      gtmEvent(`environment_${process.env.ENVIRONMENT}`);
      gtmEvent(`gtm_datalayer_initialized`);
      if ('userId' in initData && initData['userId']) {
        gtmEvent(`user_logged_in`);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    try {
      const $ = elementFactory(document, window);
      window.$ = $;
      window.toast = toastFactory(document, window, $);
    } catch (e) {
      console.log(e);
    }
  }, []);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <Provider store={store}>
      <AppComponents>
        <GlobalStyle />
        <AlertWelcome />
        <Component {...pageProps} err={err} />
        <ModalLogin />
        <ModalJoin />
        <ModalNewsletter />
        <ModalWelcome />
        <ModalYourself />
        <ModalJoinThanks />
        <ModalRegionalSettings />
        <NotificationSlider />
        <ModalConfirmation />
      </AppComponents>
    </Provider>
  );
};

export default MyApp;
